* {
  box-sizing: border-box;
  outline: none;
}

body {
  margin: 0;
  font-family: Cabin !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiAutocomplete-popper {
  width: calc(100% - 38px) !important;
  inset: 0;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.control-dots li {
  margin: 0 4px !important;
}

.control-dots .selected {
  background: #fcc425 !important;
  width: 12px !important;
  height: 12px !important;
}

.MuiAutocomplete-popper .MuiButtonBase-root {
  height: 40px;
}

.MuiAutocomplete-popper .MuiPaper-elevation {
  box-shadow: unset;
}

.MuiAutocomplete-popper .MuiPaper-elevation ul {
  max-height: 180px;
  padding-top: 0;
  border-radius: 4px;
  border-right: 1px solid #e3e4e4;
  border-left: 1px solid #e3e4e4;
  border-bottom: 1px solid #e3e4e4;
  margin-top: 5px;
  margin-bottom: 5px;
}

.MuiAutocomplete-popper li {
  border-bottom: unset;
  border-top: 1px solid #e3e4e4;
}

.MuiAutocomplete-popper li:last-child {
  border-bottom: unset;
}

code {
  font-family: Cabin;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 8px;
}
